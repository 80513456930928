/* Comment to workaround CSS ordering bug (https://github.com/vercel/next.js/issues/16630#issuecomment-1037305875) */
@import '../../../libs/component-library/src/global-styles.css';
@import '@aws-amplify/ui-react/dist/styles.css';

/* Patch to remove style in amplify stylesheet */
body {
  min-height: auto;
}

#__next {
  @apply h-full;
}

:root {
  --amplify-colors-background-primary: var(--white-pure);
  --amplify-colors-background-secondary: var(--grey-6);
  --amplify-colors-font-primary: var(--grey-1);
  --amplify-colors-brand-primary-10: var(--teal-light);
  --amplify-colors-brand-primary-100: var(--teal-dark);
  --amplify-colors-brand-primary-90: var(--teal-dark);
  --amplify-colors-brand-primary-80: var(--teal-dark);
}

/* Sign in and sign up */
.global-auth-wrapper {
  background-color: var(--white-pure) !important;
}

.global-auth-wrapper:before {
  content: '';
  background-color: var(--white-pure) !important;
  background: url('../public/images/logo.png') no-repeat center;
  background-size: 200px;
  background-position: 50% 0.5em;
  height: 5rem;
}

@media (min-width: 768px) {
  .global-auth-wrapper:before {
    background-position: 0.5rem 0.5rem;
  }
}

.global-auth-wrapper .amplify-button {
  border-radius: 2em;
}

.global-auth-wrapper.sign-in button.amplify-button {
  display: none;
}

.global-auth-wrapper .amplify-field__show-password {
  border-radius: 0;
}

.global-auth-wrapper .amplify-passwordfield {
  display: none;
}

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  box-shadow: none;
}

.amplify-tabs {
  display: none;
}

.amplify-button[data-variation='primary'] {
  background: var(--coral-med);
  border: none;
  box-shadow: none;
  color: var(--white-pure);
}

.amplify-button[data-variation='primary']:disabled {
  background: var(--grey-5);
  color: var(--grey-3);
  box-shadow: inset 0 5px 4px 0 rgba(0, 0, 0, 0.05);
}

[data-amplify-footer] {
  display: none;
}

/* SCROLLBAR STYLES */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cacdce;
  border-radius: 10px;
}

.rangeSlider {
  -webkit-appearance: none;
  width: 100%;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.rangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: rgba(241, 102, 47, 1);
  cursor: pointer;
}

.rangeSlider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: rgba(241, 102, 47, 1);
  border-radius: 10px;
  cursor: pointer;
}

#refer-and-win input#mobile-number {
  border-width: 0px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

#refer-and-win input#mobile-number::placeholder {
  color: #959a9d;
}

/* Chrome, Safari, Edge, Opera */
input.no-spin-button::-webkit-outer-spin-button,
input.no-spin-button::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-spin-button[type='number'] {
  -moz-appearance: textfield;
}
